<template>
  <div>
    <div class="mt-5">
      <p class="text-center font-size-22  text-black font-weight-bold">{{ $t('auth.otbTitle') }}</p>
      <p class="text-center font-size-22  text-black font-weight-bold"><span class="text-primary">{{country_code}}{{phoneNumber}}</span>
        {{ $t('auth.otbInfo') }} </p>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="otbForm">
      <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(checkOtb)">
        <div>
          <input-form
              class="mb-3"
              v-model="otp"
              placeholder="######"
              :validate="'required|digits:6'"
              :name="$t('auth.codeOtb')"
              :label="$t('endUser.otbCode')"
          />
        </div>
        <div class="d-flex justify-content-center mt-4" >
          <b-button class="text-white w-50 py-2" variant="primary" type="submit" v-if="!loadingChecking">
            <span> {{ $t('auth.continueOtb') }} </span>
          </b-button>
          <b-button v-else class=" text-white w-50 py-2" variant="primary" disabled>
            <spinner-loading text="saving"/>
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
// import { core } from '@/config/pluginInit'
import authService from '../services/auth.js'
export default {
  name: 'otb',
  props: ['phoneNumber', 'country_code'],
  data () {
    return {
      otp: '',
      loadingChecking: false
    }
  },
  mounted () {
    // core.index()
  },
  methods: {
    sendOtp () {
      authService.sendOtp({
        phone: this.phoneNumber ? this.phoneNumber : localStorage.getItem(),
        country_code: this.country_code
      })
    },
    checkOtb () {
      authService.checkOtp({
        phone: this.phoneNumber,
        country_code: this.country_code,
        otp: this.otp
      }).then(res => {
        this.$router.push({ name: 'login' })
      })
    }
  },
  created () {
    this.sendOtp()
  }
}
</script>

<style scoped>

</style>
